import React from "react";
import PropTypes from "prop-types";
import {
  Information,
} from "../../../formGroups/PropertyDetailsGroup";



// PropTypes
OtherInformation.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func,
};

export default function OtherInformation({ control, register }) {
  return (
    <>
      <Information control={control} required={false} rows={4} register={register} label="Other Information" />
    </>
  );
}
